<template>
<div class="display-block">
    <h3>Staff Member</h3>
    <v-card>
        <v-card-text>
            <v-row>
                <row-item class="mx-3" label="FirstName : " :value="staff.firstName"></row-item>
                <row-item class="mx-2" label="MiddleName : " :value="staff.middleName"></row-item>
                <row-item class="mx-2" label="LastName : " :value="staff.lastName"></row-item>
            </v-row>
            <row-item label="Mobile No : " :value="staff.mobileNo.toString()"></row-item>
            <row-item label="E-Mail : " :value="staff.email"></row-item>
            <row-item label="Role : "></row-item>
            <v-row>
            <div v-for="role in staff.roles" :key="role.id">
               <v-chip class="ma-2 primary-color-inverted" pill >{{role.displayName}}</v-chip>
            </div>
          </v-row>
        </v-card-text>
        <v-flex text-right>
            <v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn>
        </v-flex>
    </v-card>
</div>
</template>

<script>
import RowItem from '@/components/RowItem'
import appConstants from '@/utils/appConstants'
import axios from 'axios'
export default {
    components: {
        RowItem,
    },
    data() {
        return {
            staff: {
                firstName: "",
                middleName: "",
                lastName: "",
                mobileNo: "",
                email: "",
                roles:[]
            },
            items: [],
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.staff = await this.getItem(appConstants.STAFFS_API + "/" + this.id)
        },
    }
}
</script>
